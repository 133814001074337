@import '../../styles/app';

.tops {
  background-color: #a2223e;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #ffffff;
}

.logo {
  position: absolute;

  // @media (min-height: 940px) {
  //   position: fixed;
  // }

  left: 30px;
  top: 18px;
  z-index: 1;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.05em;
  color: $white;

  &:hover,
  &:focus {
    color: $white;
  }

  @media (max-width: breakpoint-max(sm)) {
    top: 10px;
    left: auto;

    strong {
      display: block;
    }
  }

  &.logoHidden {
    display: none;
  }
}

// .alert {
//   display: block;
//   margin: 0;
//   transition: margin-top 0.2s ease;
//   background: $text-transparent;
//   border: none;
//   font-size: $font-size-sm;
//   line-height: 20px;
//   padding: 2px 8px;
//   color: #f8f8f8;
//   border-radius: 10px;

//   button {
//     color: #f8f8f8;
//     font-weight: bold;
//   }
// }

.navbar-login {
  color: #f8f8f8;
}

.searchCollapse {
  display: block;

  @media (max-width: breakpoint-max(sm)) {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;

    &:global(.collapsing) {
      display: block;
    }

    .navbarForm {
      width: auto;
      margin: 0 2.5641%;
    }
  }
}

.navbarForm {
  padding: 6px 0;
  margin-left: 10px;
  width: 200px;
  transition: width 0.2s ease-in-out;

  .inputAddon {
    border: none;
    background-color: transparentize($text-transparent, 0.1);
    transition: background-color ease-in-out 0.15s;
  }

  input {
    font-size: 13px;
    color: $widget-color;
    background-color: transparentize($text-transparent, 0.1);
    border: none;
    padding: 0.6rem 0.85rem 0.6rem 0;
    min-height: 30px !important;

    &:focus {
      box-shadow: $input-box-shadow !important;
    }
  }
}

.navbarFormFocused {
  width: 240px;
}

.divider {
  display: block;
  width: 1px;
  margin: 0 20px;
  background: linear-gradient(rgba($text-transparent, 0), $text-transparent, rgba($gray-600, 0));
}

.navItem {
  color: $text-transparent;
  font-size: 1.5rem;
  outline: 0;
  text-align: center;

  &:hover,
  &:focus {
    color: $white;
  }

  :global(.glyphicon),
  :global(.fa) {
    vertical-align: baseline;
  }
}

.image {
  float: left;
  width: 40px;
  margin-top: 7px;
}

.imageAccount {
  width: 50px;
  margin-right: 5px;
}

.dropdownMenu {
  background: $header-dropdown-bg;
  color: #333;
  right: 0;
  left: auto;

  @media (max-width: breakpoint-max(xs)) {
    right: auto;
    left: -100px;
  }

  &::after {
    left: auto !important;
    right: 15px;

    @media (max-width: breakpoint-max(xs)) {
      right: 112px;
    }
  }

  :global(.show) > & {
    display: block;
  }
}

.messages {
  &::after {
    left: auto !important;
    right: 212px;

    @media (max-width: breakpoint-max(xs)) {
      right: 212px;
    }
  }

  button {
    display: flex;
    height: 60px;
    font-weight: $font-weight-base;
    padding-left: $spacer;
    padding-right: $spacer;

    &:last-child {
      height: 30px;
      justify-content: center;
    }
  }

  .details {
    margin-left: 10px;
    margin-top: 7px;
    .text {
      font-size: $font-size-sm;
      color: #333;
    }
  }
}

.support {
  &::after {
    @media (max-width: breakpoint-max(xs)) {
      right: 102px;
    }
  }

  :global(.badge) {
    padding-left: 5px;
    padding-right: 5px;
  }

  button {
    display: flex;
    padding: 5px 10px;
    font-weight: $font-weight-base;

    &:last-child {
      height: 30px;
      justify-content: center;
    }

    .details {
      margin-left: 7px;
      font-size: $font-size-sm;
    }
  }
}

.account {
  width: 180px;

  section {
    padding: 5px 10px;
    font-weight: normal;
  }

  button {
    padding: 7px 10px;
    font-weight: $font-weight-thin;

    a {
      color: $gray-600;
      padding: 0;
    }

    i {
      margin-right: 3px;
    }
  }
}

.settings {
  border-radius: 3px !important;
  width: 122px;
  text-align: center;
  min-width: auto;
  left: -40px !important;

  &::after {
    right: 53px !important;
    left: auto;
  }
}

.count {
  position: absolute;
  bottom: 6px;
  right: 4px;
  font-size: 0.56rem;
  font-weight: normal;
  background: rgba(51, 51, 51, 0.55);
  color: rgba(255, 255, 255, 0.9);
  line-height: 1em;
  padding: 2px 4px;
  border-radius: 10px;
}
